import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const FileUpload = ({ setFile, setFileName, fileName }: any) => {
  const handleChange = (e: any) => {
    if (e.target.files.length > 0) {
      setFileName(e.target.files[0].name);
      setFile(e.target.files[0]);
    }
  };

  const handleClear = () => {
    setFileName("");
  };

  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Button
        variant="contained"
        component="label"
        color="primary"
        sx={{ mr: 3 }}
      >
        {" "}
        <AddIcon sx={{ mr: 1 }} /> Select a file
        <input type="file" hidden onChange={handleChange} />
      </Button>
      {fileName.length > 0 ? (
        <>
          <Typography sx={{ mr: 3 }}>{fileName && fileName}</Typography>
          <Button size="small" variant="contained" onClick={handleClear}>
            Clear
          </Button>
        </>
      ) : (
        <></>
      )}
    </Stack>
  );
};

export default FileUpload;
