import React from "react";
import { AppBar, Container, Box } from "@mui/material";
import JJLogo from "../Images/JJLogo";

const Header = () => {
  return (
    <AppBar sx={{ backgroundColor: "#fff", color: "#212121" }}>
      <Container
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "76px",
          alignItems: "center",
        }}
        maxWidth="xl"
      >
        <Box sx={{ pr: 9 }}>
          <JJLogo />
        </Box>
      </Container>
    </AppBar>
  );
};

export default Header;
