import React, { useState } from "react";
import {
    Button,
    TextField,
    Container,
    Typography,
    makeStyles,
    Paper,
} from "@material-ui/core";
import JJLogo from "../../Images/JJLogo";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
    },
    paper: {
        padding: theme.spacing(3),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "40%",
        borderRadius: '40px',
        border: '1px solid rgb(229, 44, 67)',
    },
    logo: {
        marginBottom: theme.spacing(2),
        width: 100,
    },
    textField: {
        margin: theme.spacing(1),
        width: "100%",
    },
    button: {
        margin: theme.spacing(2, 0),
        width: "50%",
        background: 'rgb(229, 44, 67)',
        '&:hover': {
            background: 'rgb(229, 44, 67)',
            opacity: '0.8',
        }
    },
    label: {
        marginBottom: theme.spacing(1),
        textAlign: "left",
        fontWeight: "bold",
        width: "100%",
    },
    loginTitle: {
        fontWeight: "bold",
        marginBottom: theme.spacing(6),
        marginTop: theme.spacing(2),
    },
}));

const LoginPage = ({ onLogin }) => {
    const classes = useStyles();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = () => {
        if (username === "admin" && password === "Decoupling$2023") {
            onLogin();
        } else {
            alert("Invalid credentials");
        }
    };

    return (
        <Container className={classes.container}>
            <Paper elevation={3} className={classes.paper}>
            <JJLogo />
                <Typography variant="h5" className={classes.loginTitle} gutterBottom>
                    Login to Update fedex data
                </Typography>
                <label className={classes.label}>User Name</label>
                <TextField
                    placeholder="Enter your username"
                    variant="outlined"
                    className={classes.textField}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    InputProps={{ disableHover: true }}
                />
                <label className={classes.label}>Password</label>
                <TextField
                    placeholder="Enter your password"
                    type="password"
                    variant="outlined"
                    className={classes.textField}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{ disableHover: true }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleLogin}
                    className={classes.button}
                >
                    Login
                </Button>
            </Paper>
        </Container>
    );
};

export default LoginPage;