import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const BasicSelect = ({ country, setCountry }: any) => {
  const handleChange = (event: SelectChangeEvent) => {
    setCountry(event.target.value as string);
  };

  return (
    <>
      <Box sx={{ minWidth: 200, mr: 3 }}>
        <FormControl fullWidth required>
          <InputLabel id="demo-simple-select-label">Country</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={country}
            label="Country"
            onChange={handleChange}
            required
          >
            <MenuItem value={"NA"}>NA</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default BasicSelect;
