import React, { useState, useEffect } from "react";
import { Container, Button, Stack, Box } from "@mui/material";
import { styled } from "@mui/system";
import BasicSelect from "./Components/BasicSelect.tsx";
import FileUpload from "./Components/FileUpload.tsx";
import Header from "./Components/Header.tsx";
import Login from "./pages/Login";

const StyledBox = styled(Box)({
  position: "relative",
});

const LogoutButton = styled(Button)({
  position: "absolute",
  top: 10,
  right: 10,
});

function App() {
  // Create state to store file
  const [file, setFile] = useState(null);
  const [country, setCountry] = useState("NA");
  const [fileName, setFileName] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [isLoggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (file !== null && country.length > 0 && fileName.length > 1) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [country, file, fileName]);

  const uploadFile = async () => {
    setDisabled(true); // Disable the button to prevent multiple uploads

    // Create a FormData object to send the file
    const formData = new FormData();
    formData.append("file", file);
    const headers = {
      "api_name": "updateFedexData",
      "jjcc_region": process.env.REACT_APP_JJCC_REGION
    };

    try {
      // Send a POST request to the Node.js API endpoint
      let api = process.env.REACT_APP_FEDEX_API;
      console.log('======api', api,headers);
      const response = await fetch(api, {
        method: "POST",
        body: formData,
        headers: headers
      });

      // Check if the request was successful
      if (response.ok) {
        // Reset the form after successful upload
        setCountry("NA");
        setFile(null);
        setFileName("");
        setDisabled(false); // Enable the button
        alert("File uploaded successfully!");
      } else {
        // Handle errors if the request was not successful
        throw new Error("Failed to upload file");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Failed to upload file");
      setDisabled(false); // Enable the button
    }
  };

  const handleLogout = () => {
    setLoggedIn(false);
  };

  if (!isLoggedIn) {
    return <Login onLogin={() => setLoggedIn(true)} />;
  }

  return (
    <div className="App">
      <StyledBox>
        <LogoutButton variant="contained" onClick={handleLogout}>
          Logout
        </LogoutButton>
        <Header />
      </StyledBox>
      <Container sx={{ mt: 12, display: "flex", flexDirection: "column", ml: 4 }}>
        <h2 style={{ paddingBottom: "10px" }}>FEDEX DATA UPLOAD</h2>
        <Stack display={"flex"} direction={"row"}>
          <BasicSelect country={country} setCountry={setCountry} />
          <FileUpload setFile={setFile} fileName={fileName} setFileName={setFileName} />
        </Stack>
        <Stack width={"15%"} height={"25px"} mt={"30px"}>
          <Button
            color="primary"
            variant="contained"
            component="span"
            size="large"
            onClick={uploadFile}
            disabled={disabled}
          >
            Upload
          </Button>
        </Stack>
      </Container>
    </div>
  );
}

export default App;
